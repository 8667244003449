<template>
  <vs-popup
    classContent="popup-example"
    :title="lang.security.ipRanges.edit.popUpTitle[languageSelected]"
    :active.sync="activePopup"
  >
    <div>
      <div class="vx-row">
        <vs-col class="w-full">
          <vs-input
            class="mt-2 w-full mb-base"
            v-model="newRange"
            :label="lang.security.ipRanges.edit.range[languageSelected]"
            :disabled="saving"
          ></vs-input>
        </vs-col>
      </div>

      <vs-divider />

      <vs-button class="float-right" @click="save" :disabled="saving">{{
        lang.security.ipRanges.edit.saveText[languageSelected]
      }}</vs-button>
      <vs-button
        class="float-right mr-4"
        color="danger"
        @click="cancel"
        :disabled="saving"
        >{{
          lang.security.ipRanges.edit.cancelText[languageSelected]
        }}</vs-button
      >
    </div>
  </vs-popup>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'EditIPRange',
  components: {},
  props: {
    item: Object
  },
  data() {
    return {
      activePopup: false,
      saving: false,

      newRange: ''
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  watch: {},
  methods: {
    ...mapActions('ipRanges', ['UPDATE_IP_RANGE']),
    openPopup() {
      this.activePopup = true
      this.updateFields(this.item)
    },
    updateFields(newVal) {
      this.newRange = newVal.range
    },
    cancel() {
      this.activePopup = false
    },
    async save() {
      if (!this.newRange || this.newRange.trim().length === 0) {
        return this.$vs.notify({
          title: this.lang.security.ipRanges.validationErrors.emptyRange.title[
            this.languageSelected
          ],
          text: this.lang.security.ipRanges.validationErrors.emptyRange.text[
            this.languageSelected
          ],
          color: 'warning',
          position: 'top-right'
        })
      }
      this.saving = true
      try {
        const message = {
          body: {
            range: this.newRange
          },
          _id: this.item._id
        }
        await this.UPDATE_IP_RANGE(message)
        this.$vs.notify({
          title: this.lang.security.ipRanges.edit.success.title[
            this.languageSelected
          ],
          text: this.lang.security.ipRanges.edit.success.text[
            this.languageSelected
          ],
          color: 'success',
          position: 'top-right'
        })
        this.cancel()
      } catch (error) {
        if (error && error.response && error.response.status === 400) {
          this.$vs.notify({
            title: this.lang.security.ipRanges.validationErrors.notValid.title[
              this.languageSelected
            ],
            text: this.lang.security.ipRanges.validationErrors.notValid.text[
              this.languageSelected
            ],
            color: 'warning',
            position: 'top-right'
          })
        } else {
          console.error(error)
          this.$vs.notify({
            title: this.lang.security.ipRanges.edit.error.title[
              this.languageSelected
            ],
            text: this.lang.security.ipRanges.edit.error.text[
              this.languageSelected
            ],
            color: 'warning',
            position: 'top-right'
          })
        }
      }
      this.saving = false
    }
  }
}
</script>
