<template>
  <div>
    <AddIPRange ref="addIPRange" />
    <EditIPRange ref="editIPRange" :item="selected" />
    <vs-card id="ip-ranges-view">
      <div slot="header">
        <vs-row vs-align="center">
          <vs-col vs-w="12">
            <h3>{{ lang.security.ipRanges.title[languageSelected] }}</h3>
          </vs-col>
        </vs-row>
      </div>
      <div>
        <vs-row vs-align="center">
          <vs-col class="w-full">
            <form>
              <p class="form-group-label mt-base mb-2">
                {{ lang.security.ipRanges.slider.label[languageSelected] }}
              </p>
              <vx-slider v-model="security.ipFiltering" />
            </form>
          </vs-col>
        </vs-row>
        <vs-row vs-align="center">
          <vs-col>
            <vs-button
              class="float-right mt-2"
              icon="add"
              @click="toggleAddIPRange"
              :disabled="!security.ipFiltering"
            >
              {{ lang.security.ipRanges.add.popUpTitle[languageSelected] }}
            </vs-button>
          </vs-col>
          <vs-col class="w-full">
            <IPRangesTable
              :toggleIPRangeEdit="toggleIPRangeEdit"
              :actionsDisabled="!security.ipFiltering"
            />
          </vs-col>
        </vs-row>
      </div>
    </vs-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import IPRangesTable from './IPRangesTable'
import AddIPRange from './AddIPRange'
import EditIPRange from './EditIPRange'

export default {
  name: 'IPRanges',
  components: {
    IPRangesTable,
    EditIPRange,
    AddIPRange
  },
  data() {
    return {
      selected: null
    }
  },
  computed: {
    ...mapState(['languageSelected', 'lang', 'session', 'security'])
  },
  methods: {
    ...mapActions('ipRanges', ['GET_IP_RANGE']),
    ...mapActions(['saveCompanySecuritySettings']),
    toggleAddIPRange() {
      this.$nextTick(() => {
        this.$refs.addIPRange.openPopup()
      })
    },
    toggleIPRangeEdit(tr) {
      this.selected = tr
      this.$nextTick(() => {
        this.$refs.editIPRange.openPopup()
      })
    }
  },
  async mounted() {
    this.$vs.loading()
    try {
      await this.GET_IP_RANGE()
    } catch (ex) {
      console.error(ex)
    }
    this.$vs.loading.close()
  },
  watch: {
    'security.ipFiltering'() {
      this.$vs.loading()
      this.saveCompanySecuritySettings({ ...this.security })
        .then(async () => {
          this.$vs.notify({
            color: 'success',
            title: this.lang.security.ipRanges.slider.success.title[
              this.languageSelected
            ],
            text: this.lang.security.ipRanges.slider.success.text[
              this.languageSelected
            ]
          })
        })
        .catch(error => {
          this.$vs.notify({
            color: 'danger',
            title: this.lang.security.ipRanges.slider.error.title[
              this.languageSelected
            ],
            text: this.lang.security.ipRanges.slider.error.text[
              this.languageSelected
            ]
          })
          this.$log.error(error)
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    }
  }
}
</script>

<style type="scss" scoped>
.action-buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 19px;
}
.with-space {
  margin-right: 5px;
}
.form-group-label {
  font-size: 14px;
}
</style>
