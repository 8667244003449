<template>
  <div id="ip-ranges-table" v-if="ipRanges">
    <vs-table
      max-items="20"
      pagination
      search
      :data="ipRanges"
      :no-data-text="lang.general.noDataText[languageSelected]"
    >
      <template slot="thead">
        <vs-th sort-key="range">
          {{ lang.security.ipRanges.table.range[languageSelected] }}
        </vs-th>
        <vs-th sort-key="_updatedAt">
          {{ lang.security.ipRanges.table._updatedAt[languageSelected] }}
        </vs-th>
        <vs-th sort-key="_createdAt">
          {{ lang.security.ipRanges.table._createdAt[languageSelected] }}
        </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr.range">{{ tr.range }}</vs-td>
          <vs-td :data="tr._updatedAt">{{
            new Date(tr._updatedAt || tr._createdAt) | date
          }}</vs-td>
          <vs-td :data="tr._createdAt">{{
            new Date(tr._createdAt) | date
          }}</vs-td>

          <vs-td>
            <div class="action-buttons">
              <vs-button
                class="with-space"
                @click="edit(tr)"
                icon="create"
                type="flat"
                :disabled="actionsDisabled"
              />
              <vs-button
                @click="remove(indextr)"
                icon-pack="feather"
                icon="icon-trash"
                type="flat"
                :disabled="actionsDisabled"
              />
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'IPRangesTable',
  props: {
    toggleIPRangeEdit: Function,
    actionsDisabled: Boolean
  },
  data() {
    return {
      selected: null
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('ipRanges', ['ipRanges'])
  },
  methods: {
    ...mapActions('ipRanges', ['DELETE_IP_RANGE']),
    edit(tr) {
      this.toggleIPRangeEdit(tr)
    },
    remove: function(indextr) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.security.ipRanges.remove.dialog.title[
          this.languageSelected
        ],
        text: this.lang.security.ipRanges.remove.dialog.text[
          this.languageSelected
        ],
        accept: async () => {
          this.$vs.loading()
          try {
            await this.DELETE_IP_RANGE({ _id: this.ipRanges[indextr]._id })
            this.$vs.notify({
              title: this.lang.security.ipRanges.remove.success.title[
                this.languageSelected
              ],
              text: this.lang.security.ipRanges.remove.success.text[
                this.languageSelected
              ],
              color: 'success',
              position: 'top-right'
            })
            this.$vs.loading.close()
          } catch (error) {
            this.$vs.loading.close()
            this.$vs.notify({
              title: this.lang.security.ipRanges.remove.error.title[
                this.languageSelected
              ],
              text: this.lang.security.ipRanges.remove.error.text[
                this.languageSelected
              ],
              color: 'danger',
              position: 'top-right'
            })
          }
        }
      })
    }
  }
}
</script>

<style type="scss" scoped>
.action-buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 19px;
}
.with-space {
  margin-right: 5px;
}
</style>
